.box {
  background-color: rgba(249, 222, 209, 0.5);
  @include spacing('padding');
  @include spacing('margin-bottom');
  @include spacing('border-radius', 'small');
  max-width: calc(100vw - 2 * get-spacing());
  overflow: hidden;

  &__title {
    font-weight: 600;
    font-variation-settings: 'wght' 600;
    @include typography('title');
    @include spacing('margin-bottom', 'tiny');
  }
}
