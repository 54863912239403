.footer {
  height: 100vh;
  overflow: hidden;
  text-align: center;
  contain: strict;

  &__logo {
    width: 10vmin;
  }

  &__circle {
    background-color: $color-background-inverted;
    color: $color-foreground-inverted;
    border-radius: 100%;
    height: 1vmin;
    width: 1vmin;
    position: absolute;
    top: 30%;
    left: 50%;
    transform: translateX(-50%) translateY(-50%);
    transition: all get-duration('slower');

    a {
      color: $color-foreground-inverted;

      &:hover {
        color: tint-color($color-accent, 20%);
      }
    }
  }

  &.section--visible {

    .footer__circle {
      height: 160vmin;
      width: 160vmin;
      top: 60%
    }

    .section__column {
      opacity: 1;
      transition: opacity get-duration('slow') linear get-duration('slower');
    }
  }

  .container {
    max-width: 100vw;
  }

  .section__row {
    align-items: center;
    height: 160vmin;
  }

  .section__column {
    opacity: 0;
    transition: opacity get-duration('slow') linear get-duration('slower');
  }
}
