@use 'sass-mq/mq' as *;

.header {
  @include spacing('padding-top');
  height: 100vh;
  overflow: hidden;
  contain: strict;

  &__hero {
    display: grid;
    @include gutter($grid-gutter, 'gap');
    max-height: 90vh;

    @include mq('xs', 'md') {
      grid-template-columns: repeat(4, 1fr);
      grid-template-areas:
        'logo logo . .'
        'anchors anchors . .'
        'shape shape shape shape'
        'social social social social';
    }

    @include mq('md', 'lg') {
      grid-template-columns: repeat(6, 1fr);
      grid-template-areas:
        'logo logo . . . .'
        'anchors anchors shape shape shape shape'
        '. . social social . .';
    }

    @include mq('lg') {
      grid-template-columns: repeat(12, 1fr);
      grid-template-areas:
        'logo logo logo . . . . . . . . .'
        'anchors anchors shape shape shape shape shape shape shape shape social social';
    }
  }

  &__logo {
    grid-area: logo;
  }

  &__anchors {
    grid-area: anchors;
    text-transform: uppercase;
    font-weight: 700;
    font-variation-settings: 'wght' 700;
    @include spacing('margin-top');

    li {
      @include spacing('margin-bottom', 'small');
    }

    a,
    a:hover {
      text-decoration: none;
    }
  }

  &__shape {
    grid-area: shape;
    justify-self: center;
    width: 60vmin;
    height: 60vmin;
  }

  &__shape-animation {
    width: 60vmin;
    height: 60vmin;
    @include spacing('padding');
    background: linear-gradient(117.85deg, get-color(pink) 20.62%, get-color(yellow) 101.31%);
    -webkit-clip-path: url(#svgPath);
    clip-path: url(#svgPath);
    position: relative;
    animation-duration: 12s;
    animation-name: rotateClockwise;
    animation-iteration-count: infinite;
    animation-timing-function: linear;

    &::before {
      content: '';
      display: block;
      padding-bottom: 100%;
      float: left;
    }

    .section__title {
      font-size: 8vmin;
      animation-duration: 12s;
      animation-name: rotateCounterclockwise;
      animation-iteration-count: infinite;
      animation-timing-function: linear;
    }
  }

  &__social {
    grid-area: social;
    @include spacing('margin-top');

    ul {
      display: flex;
      @include gutter(get-half-sizing($grid-gutter), 'gap');

      @include mq('xs', 'md') {
        justify-content: center;
      }

      @include mq('lg') {
        flex-direction: column;
        justify-content: right;
      }
    }

    li {
      width: 3rem;
    }

    a {
      display: block;
    }
  }
}

@keyframes rotateClockwise {
  from {
    transform: rotate(0deg);
    padding-top: 30%;
  }

  50% {
    transform: rotate(180deg);
    padding-top: 35%;
  }

  to {
    transform: rotate(360deg);
    padding-top: 30%;
  }
}

@keyframes rotateCounterclockwise {
  from {
    transform: rotate(360deg);
  }

  50% {
    transform: rotate(180deg);
  }

  to {
    transform: rotate(0deg);
  }
}
