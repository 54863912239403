@use 'sass-mq/mq' as *;

.gallery {
  overflow-x: scroll;
  scroll-snap-type: x mandatory;
  @include spacing('margin-bottom');

  @include mq('xs', 'sm') {
    @include spacing('margin-left', $negative: true);
    @include spacing('margin-right', $negative: true);
  }

  &__grid {
    display: grid;
    @include gutter($grid-gutter, 'gap');

    @include mq('xs', 'sm') {
      grid-template-columns: 1fr 1fr 1fr 1fr 1fr 1fr;
      width: 450vw;
      @include spacing('padding-left');
      @include spacing('padding-right');
    }

    @include mq('sm', 'md') {
      grid-template-columns: 1fr 1fr;
    }

    @include mq('md') {
      grid-template-columns: 1fr 1fr 1fr;
    }

    li {
      scroll-snap-align: center;
    }
  }

}
