.blockquote {
  @include spacing('padding');
  @include spacing('margin-bottom');

  &__quote {
    @include typography('blockquote');
    font-weight: 700;
    font-variation-settings: 'wght' 700;
  }

  &__cite {
    text-align: center;
  }
}
