.person {

  &__image {
    @include spacing('margin-bottom', 'small');
    @include spacing('border-radius', 'small');
  }

  &__name {
    font-weight: 600;
    font-variation-settings: 'wght' 600;
    @include typography('title');
    margin-top: 0;
    text-align: center;
    @include spacing('margin-bottom', 'tiny');
  }

  &__description {
    text-align: center;
    @include spacing('margin-bottom', 'tiny');
  }

  &__links {
    text-align: center;

    li {
      display: inline-block;
      @include spacing('padding-left', 'tiny');
      @include spacing('padding-right', 'tiny');
    }
  }
}
