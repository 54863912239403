@use 'sass-mq/mq' as *;

.teaser {
  @include spacing('margin-bottom');
  display: grid;

  @include mq('xs', 'md') {
    grid-template-rows: auto;
    grid-template-areas: 'content' 'image';
    @include gutter(map-get($token-spacings, 'tiny'), 'gap');
  }

  @include mq('md', 'lg') {
    grid-template-columns: repeat(2, 1fr);
    grid-template-areas: 'image content';
    @include gutter($grid-gutter, 'gap');
  }

  @include mq('lg') {
    grid-template-columns: repeat(6, 1fr);
    grid-template-areas: 'image image image content content .';
    @include gutter($grid-gutter, 'gap');
  }

  &--left {
    @include mq('md', 'lg') {
      grid-template-areas: 'content image';
    }

    @include mq('lg') {
      grid-template-areas: '. content content image image image';
    }
  }

  &--link {
    background-color: $color-background;
    transition: background-color get-duration('slow');

    .teaser__image img {
      transition: transform get-duration('slow');
      transform: scale(1);
    }

    .teaser__title {
      color: $color-foreground;
      transition: color get-duration('slow');
    }
  }

  &--link:hover {
    background-color: tint-color($color-accent, 95%);

    .teaser__image img {
      transform: scale(1.05);
    }

    .teaser__title{
      color: $link-hover-color;
    }
  }

  &__content {
    grid-area: content;
  }

  &__image {
    overflow: hidden;
    grid-area: image;
  }

  &__title {
    font-weight: 600;
    font-variation-settings: 'wght' 600;
    @include typography('title');
    @include spacing('margin-bottom', 'tiny');
  }

  &__dateline {
    font-weight: 400;
    font-variation-settings: 'wght' 400;
    @include typography('dateline');
    display: block;
    text-transform: uppercase;
    @include spacing('margin-bottom', 'tiny');
  }

}
