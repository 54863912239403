@use 'sass-mq/mq' as *;

@font-face {
  font-family: 'Inter';
  src: url('/assets/fonts/Inter-VariableFont_slnt,wght.ttf');
}

.section {
  position: relative;
  @include spacing('padding-top', 'large');
  @include spacing('padding-bottom');

  &__title {
    text-transform: uppercase;
    font-weight: 100;
    font-variation-settings: 'wght' 100;
    transition: all get-duration('slower') get-easing();
    @include spacing('margin-bottom');
  }

  @at-root #{&}--visible #{&}__title {
    font-weight: 700;
    font-variation-settings: 'wght' 700;
  }

  &__word--variation1 {
    display: block;
    text-align: right;
  }

  &__word--variation2 {
    display: block;
    text-align: left;
    font-weight: 900;
    font-variation-settings: 'wght' 900;
    transition: all get-duration('slower') get-easing();
  }

  @at-root #{&}--visible #{&}__word--variation2 {
    font-weight: 400;
    font-variation-settings: 'wght' 400;
  }

  &__subtitle {
    text-transform: uppercase;
    font-weight: 100;
    font-variation-settings: 'wght' 100;
    transition: all get-duration('slower') get-easing();
    @include spacing('margin-bottom', 'tiny');
  }

  @at-root #{&}--visible #{&}__subtitle {
    font-weight: 600;
    font-variation-settings: 'wght' 600;
  }

  &__row {
    display: grid;
    @include spacing('margin-bottom');
    @include gutter($grid-gutter, 'gap');
  }

  &__row--left,
  &__row--right {
    @include mq('lg') {
      grid-template-columns: repeat(2, 1fr);
      grid-template-areas: 'left right';
    }
  }

  &__row--left {
    @include mq('md', 'lg') {
      grid-template-columns: repeat(3, 1fr);
      grid-template-areas: 'left left right';
    }

    .section__column {
      @include mq('md') {
        grid-area: left;
      }
    }
  }

  &__row--right {
    @include mq('md', 'lg') {
      grid-template-columns: repeat(3, 1fr);
      grid-template-areas: 'left right right';
    }

    .section__column {
      @include mq('md') {
        grid-area: right;
      }
    }
  }

  &__row--center {
    @include mq('md', 'lg') {
      grid-template-columns: repeat(6, 1fr);
      grid-template-areas: '. center center center center .';
    }

    @include mq('lg') {
      grid-template-columns: repeat(4, 1fr);
      grid-template-areas: '. center center .';
    }

    .section__column {
      @include mq('md') {
        grid-area: center;
      }
    }
  }

  &__row--call-to-action {
    @include mq('sm', 'md') {
      grid-template-columns: repeat(6, 1fr);
      grid-template-areas: '. right right right right .';
    }

    @include mq('md', 'lg') {
      grid-template-columns: repeat(8, 1fr);
      grid-template-areas: ' . . . right right right right .';
    }

    @include mq('lg') {
      grid-template-columns: repeat(12, 1fr);
      grid-template-areas: '. . . . . . . right right right right .';
    }

    .section__column {
      @include mq('sm') {
        grid-area: right;
      }
    }

    .section__subtitle {
      position: relative;

      &:before {
        content: '';
        position: absolute;
        top: -0.25em;
        left: -2em;
        width: 1.6em;
        height: 1.6em;
        background: linear-gradient(117.85deg, get-color(pink) 20.62%, get-color(yellow) 101.31%);
      }

      &:after {
        content: '';
        position: absolute;
        top: -0.25em;
        left: -2em;
        width: 1.6em;
        height: 1.6em;
        border: 0.8em solid $color-background;
        border-left-color: transparent;
        border-left-width: 1.6em;
        border-right-width: 0;
      }
    }
  }

  &__row--box {
    @include mq('md', 'lg') {
      grid-template-columns: repeat(3, 1fr);
      grid-template-areas: '. box box';
    }

    @include mq('lg') {
      grid-template-columns: repeat(6, 1fr);
      grid-template-areas: '. . box box box .';
    }

    .section__column {
      @include mq('md') {
        grid-area: box;
      }
    }
  }

  &__row--3columns {
    @include mq('sm', 'md') {
      grid-template-columns: repeat(2, 1fr);
    }

    @include mq('md') {
      grid-template-columns: repeat(3, 1fr);
    }

    .section__column {
      @include mq('sm') {
        grid-column: auto / span 1;
      }
    }
  }

  &__all-items {
    text-align: right;
  }
}
